import {
  IFacilityDTO,
  IFacilityUpdate,
  IGetLocationFacilityDTO,
  ITenantFacilityParameters,
} from "@/models/Facility";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const FACILITY = "Facilities";
export class FacilityAPI extends HttpClient {
  constructor(
    private env = JSON.parse(localStorage.getItem("env") as string),
    tenantId?: string
  ) {
    super(env?.VUE_APP_DOMAIN_API_SERVER, tenantId);
  }
  public createFacility = async (data: IFacilityDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${FACILITY}`, data)
      .catch(catchAxiosError);
    return response;
  };

  public getListFacility = async (
    params: ITenantFacilityParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}`, { params })
      .catch(catchAxiosError);
    return response;
  };

  public updateFacility = async (
    FacilityId: string,
    data: IFacilityUpdate
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${FACILITY}/${FacilityId}`, data)
      .catch(catchAxiosError);
    return response;
  };

  public getFacility = async (FacilityId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}`)
      .catch(catchAxiosError);
    return response;
  };

  public updateImageFacility = async (
    FacilityId: string,
    data: FormData,
    fileId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${FACILITY}/${FacilityId}/document/${fileId}`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .catch(catchAxiosError);
    return response;
  };

  public createImageFacility = async (
    FacilityId: string,
    File: FormData
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${FACILITY}/${FacilityId}/document`, File, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .catch(catchAxiosError);
    return response;
  };

  public getImageFacility = async (FacilityId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/documents`)
      .catch(catchAxiosError);
    return response;
  };

  public getLocationFacility = async (
    payload: IGetLocationFacilityDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${FACILITY}/worldmap/${payload.year}`, payload)
      .catch(catchAxiosError);
    return response;
  };

  public getRecRetiredFacility = async (
    FacilityId: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}/recDetail`)
      .catch(catchAxiosError);
    return response;
  };
}
