import {
  IFacilityCreate,
  ITenantFacilityParameters,
  IFacilityDTO,
  IFacilityUpdate,
  IFacilityFileDTO,
  IGetFileFacilityDTO,
  IGetLocationFacilityDTO,
  IGetLocationFacilityResponseDTO,
  IGetRecDetailFacilityResponseDTO,
} from "@/models/Facility";
import { IPagination } from "@/models/Pagination";
import { ErrorResponse } from "@/services/axios/error";
import { FacilityAPI } from "@/services/facility";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { IResponse } from "@/services/axios/entities";

const name = "TenantFacilityModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class TenantFacilityModule extends VuexModule {
  private detailTenantFacility = {} as IFacilityDTO;

  private errorCreateFacility = {} as ErrorResponse;
  private errorUpdateFacility = {} as ErrorResponse;
  private errorDetailFacility = {} as ErrorResponse;

  private dataGetListTenantFacility = [] as IFacilityDTO[];
  private errorDataGetListTenantFacility = {} as ErrorResponse;
  private dataGetListImageFacility = [] as IGetFileFacilityDTO[];
  private pagination = {} as IPagination;
  private dataGetListLocationFacility = [] as IGetLocationFacilityResponseDTO[];
  private errorGetListLocationFacility = {} as ErrorResponse;

  private dataGetListRecRetiredFacility =
    [] as IGetRecDetailFacilityResponseDTO[];
  private errorGetListRecRetiredFacility = {} as ErrorResponse;
  private isLoadingListTenantFacility = false;
  private isLoadingListRecRetiredFacility = false;

  get hasCreateErrorMsg() {
    return !isEmpty(this.errorCreateFacility);
  }
  get dataTenantFacilityGetter() {
    return this.dataGetListTenantFacility;
  }
  get detailTenantFacilityGetter() {
    return this.detailTenantFacility;
  }
  get isLoadingListTenantFacilityGetter() {
    return this.isLoadingListTenantFacility;
  }
  get errorDetailFacilityGetter() {
    return this.errorDetailFacility;
  }
  get dataGetListImageFacilityGetter() {
    return this.dataGetListImageFacility;
  }
  get dataGetListLocationFacilityGetter() {
    return this.dataGetListLocationFacility;
  }

  get isLoadingListRecRetiredFacilityGetter() {
    return this.isLoadingListRecRetiredFacility;
  }
  get dataGetListRecRetiredFacilityGetter() {
    return this.dataGetListRecRetiredFacility;
  }

  @Mutation
  createFacilitySuccess() {
    ElMessage.success("Successfully added facility!");
    this.errorCreateFacility = {} as ErrorResponse;
  }
  @Mutation
  createFacilityFailure(error: ErrorResponse) {
    this.errorCreateFacility = error;
  }
  @Action({ rawError: true })
  async createFacility(payload: IFacilityCreate) {
    const servicesFacilityAPI = new FacilityAPI();

    const { data, error } = await servicesFacilityAPI.createFacility(payload);

    if (error) {
      this.createFacilityFailure(error);
    } else {
      this.createFacilitySuccess();
      return data;
    }
  }

  @Mutation
  setLoadingListTenantFacility(loading: boolean) {
    this.dataGetListTenantFacility = [] as IFacilityDTO[];
    this.isLoadingListTenantFacility = loading;
  }
  @Mutation
  getListTenantFacilitySuccess(data: any) {
    this.errorDataGetListTenantFacility = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantFacility = Collection;
    this.pagination = rest;
    this.isLoadingListTenantFacility = false;
  }
  @Mutation
  getListTenantFacilityFailure(error: ErrorResponse) {
    this.errorDataGetListTenantFacility = error;
    this.isLoadingListTenantFacility = false;
  }
  @Action({ rawError: true })
  async getListTenantFacility(payload: ITenantFacilityParameters) {
    this.setLoadingListTenantFacility(true);
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getListFacility(payload);

    if (error) {
      this.getListTenantFacilityFailure(error);
    } else {
      this.getListTenantFacilitySuccess(data);
    }
  }

  @Mutation
  updateFacilitySuccess(data: any) {
    ElMessage.success("Updated Facility success.");
    this.errorUpdateFacility = {} as ErrorResponse;
  }
  @Mutation
  updateFacilityFailure(error: ErrorResponse) {
    this.errorUpdateFacility = error;
  }
  @Action({ rawError: true })
  async updateFacility(payload: IFacilityUpdate) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.updateFacility(
      payload.FacilityId,
      payload
    );

    if (error) {
      this.updateFacilityFailure(error);
    } else {
      this.updateFacilitySuccess(data);
    }
  }

  @Mutation
  updateImageFacilitySuccess(data: any) {
    this.errorUpdateFacility = {} as ErrorResponse;
  }
  @Mutation
  updateImageFacilityFailure(error: ErrorResponse) {
    this.errorUpdateFacility = error;
  }
  @Action({ rawError: true })
  async updateImageFacility(payload: {
    FacilityId: string;
    File: FormData;
    FileId: string;
  }) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.updateImageFacility(
      payload.FacilityId,
      payload.File,
      payload.FileId
    );

    if (error) {
      this.updateImageFacilityFailure(error);
    } else {
      this.updateImageFacilitySuccess(data);
    }
  }
  @Mutation
  getTenantFacilityDetailSuccess(data: any) {
    this.errorDetailFacility = {} as ErrorResponse;
    this.detailTenantFacility = data;
  }
  @Mutation
  getTenantFacilityDetailFailure(error: ErrorResponse) {
    this.errorDetailFacility = error;
  }
  @Action({ rawError: true })
  async getFacility(payload: { FacilityId: string }): Promise<IResponse> {
    const { FacilityId } = payload;
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getFacility(FacilityId);

    if (error) {
      this.getTenantFacilityDetailFailure(error);
      return { error };
    } else {
      const images = await this.getImageFacility({ FacilityId });
      const image = images.find((x: any) => x.IsActive === true);
      data.ImageUrl = image?.Content;
      data.FileId = image?.Id;
      this.getTenantFacilityDetailSuccess(data);
      return { data };
    }
  }
  @Mutation
  resetDetailTenantFacility() {
    this.detailTenantFacility = {} as IFacilityDTO;
  }

  @Mutation
  createImageFacilitySuccess(data: any) {
    this.errorUpdateFacility = {} as ErrorResponse;
  }
  @Mutation
  createImageFacilityFailure(error: ErrorResponse) {
    this.errorUpdateFacility = error;
  }
  @Action({ rawError: true })
  async createImageFacility(payload: IFacilityFileDTO) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.createImageFacility(
      payload.FacilityId,
      payload.File
    );

    if (error) {
      this.createImageFacilityFailure(error);
    } else {
      this.createImageFacilitySuccess(data);
    }
  }
  @Mutation
  getImageFacilityFailure(error: ErrorResponse) {
    this.errorUpdateFacility = error;
  }
  @Action({ rawError: true })
  async getImageFacility(payload: { FacilityId: string }) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getImageFacility(
      payload.FacilityId
    );

    if (error) {
      this.getImageFacilityFailure(error);
    } else {
      return data;
    }
    return data;
  }

  @Mutation
  getLocationFacilityFailure(error: ErrorResponse) {
    this.errorGetListLocationFacility = error;
  }

  @Mutation
  getLocationFacilitySuccess(data: any) {
    this.dataGetListLocationFacility = data;
  }
  @Action({ rawError: true })
  async getLocationFacility(payload: IGetLocationFacilityDTO) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getLocationFacility(
      payload
    );

    if (error) {
      this.getLocationFacilityFailure(error);
    } else {
      this.getLocationFacilitySuccess(data);
    }
    return data;
  }

  @Mutation
  setLoadingListRecRetiredFacility(isLoading: boolean) {
    this.isLoadingListRecRetiredFacility = isLoading;
  }

  @Mutation
  getRecRetiredFacilityFailure(error: ErrorResponse) {
    this.errorGetListRecRetiredFacility = error;
    this.isLoadingListRecRetiredFacility = false;
  }
  @Mutation
  getRecRetiredFacilitySuccess(data: IGetRecDetailFacilityResponseDTO[]) {
    this.dataGetListRecRetiredFacility = data;
    this.isLoadingListRecRetiredFacility = false;
  }
  @Action({ rawError: true })
  async getRecRetiredFacility(payload: { facilityId: string }) {
    this.setLoadingListRecRetiredFacility(true);
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getRecRetiredFacility(
      payload.facilityId
    );
    if (error) {
      this.getRecRetiredFacilityFailure(error);
    } else {
      this.getRecRetiredFacilitySuccess(data);
    }
    return data;
  }
}
export default getModule(TenantFacilityModule);
